export function formatStringDate(stringDate) {
  const date = new Date(stringDate);
  const formatted_date = date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + ' ' +
  ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
  return formatted_date;
}

export const statusChoices = [
  {
    name: 'Brouillon',
    value: 'draft',
  },
  {
    name: 'En attente de publication',
    value: 'pending',
  },
  {
    name: 'Publié',
    value: 'published',
  },
  {
    name: 'Archivé',
    value: 'archived',
  },
];

export function allowedStatus2change(user, isModerate, userStatus, isOwnFeature, currentRouteName) {
  if ( //* si 'super-admin'(superuser) admin, modérateur ou super contributeur, statuts toujours disponibles: Brouillon, Publié, Archivé
    user.is_superuser ||
      userStatus === 'Modérateur' ||
      userStatus === 'Administrateur projet' ||
      (userStatus === 'Super Contributeur' && !isModerate)
  ) {
    return statusChoices.filter((el) => el.value !== 'pending');
  } else if (userStatus === 'Super Contributeur' && isModerate) {
    return statusChoices.filter(
      (el) => el.value === 'draft' || el.value === 'pending'
    );
  } else if (userStatus === 'Contributeur') { //* cas particuliers du contributeur
    if (currentRouteName === 'ajouter-signalement' || !isOwnFeature) {
      //* même cas à l'ajout d'une feature ou si feature n'a pas été créé par le contributeur
      return isModerate
        ? statusChoices.filter(
          (el) => el.value === 'draft' || el.value === 'pending'
        )
        : statusChoices.filter(
          (el) => el.value === 'draft' || el.value === 'published'
        );
    } else {
      //* à l'édition d'une feature et si le contributeur est l'auteur de la feature
      return isModerate
        ? statusChoices.filter(
          (el) => el.value !== 'published' //* toutes sauf "Publié"
        )
        : statusChoices.filter(
          (el) => el.value !== 'pending' //* toutes sauf "En cours de publication"
        );
    }
  }
  return [];
}

export function transformProperties(prop) {
  const type = typeof prop;
  const date = new Date(prop);
  const regInteger = /^-*?\d+$/;
  const regFloat = /^-*?\d*?\.\d+$/;
  const regText = /[\r\n]/;
  if (type === 'boolean' || (type === 'string' && (prop.toLowerCase() === 'true' || prop.toLowerCase() === 'False'))) {
    return 'boolean';
  } else if ((regInteger.test(prop) || Number.isSafeInteger(prop)) && prop !== 0) { // in case the value is 0, since it can be either float or integer, do not set to integer https://redmine.neogeo.fr/issues/16934
    return 'integer';
  } else if (
    type === 'string' &&
    ['/', ':', '-'].some((el) => prop.includes(el)) && // check for chars found in datestring
    date instanceof Date &&
    !isNaN(date.valueOf())
  ) {
    return 'date';
  } else if (regFloat.test(prop) ||  type === 'number' && !isNaN(parseFloat(prop)) || prop === 0) { // in case the value is 0, since it can be either float or integer, by default set as a float
    return 'decimal';
  } else if (regText.test(prop)) {
    return 'text';
  }
  return 'char'; //* string by default, most accepted type in database
}