import { render, staticRenderFns } from "./SearchProjects.vue?vue&type=template&id=e5046e84&scoped=true"
import script from "./SearchProjects.vue?vue&type=script&lang=js"
export * from "./SearchProjects.vue?vue&type=script&lang=js"
import style0 from "./SearchProjects.vue?vue&type=style&index=0&id=e5046e84&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5046e84",
  null
  
)

export default component.exports